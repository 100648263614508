import React, { useState, useEffect } from 'react';
import { useNavigate, createSearchParams } from 'react-router-dom'
import PropTypes from 'prop-types';
import './App.css'
import { render } from '@testing-library/react';

function App() {
  const [url, setUrl] = useState('');
  const [done, setDone] = useState(false);
  const searchParams = new URLSearchParams(window.location.search);
  const [song_id, setSongId] = useState(searchParams.get('id'));
  const navigate = useNavigate();

  const [name, setName] = useState('');
  const [spotUrl, setSpotUrl] = useState('');
  const [appleUrl, setAppleUrl] = useState('');
  const [artists, setArtists] = useState([]);
  const [imgUrl, setImgUrl] = useState('');

  useEffect(() => {
    var regex = new RegExp("^[a-zA-Z0-9]+$")
    if (song_id != null && song_id != "null" && regex.test(song_id) && !done) {
      requestSong(song_id);
      setDone(true);
    }
    //document.getElementById('urlinput').innerHTML = window.Clipboard.getData('Text');
    navigator.clipboard.readText()
      .then(text => {
        const url_regex = /^https[:\/A-Za-z0-9\.\_]+(spotify|apple)[:\/A-Za-z0-9\.\_?=]+/;
        if (url_regex.test(text)) {
          document.getElementById('urlinput').value = text;
          setUrl(text);
        }
      })
  }, [name, spotUrl, appleUrl, artists, imgUrl, done]);
  // do a loading screen so it looks fucking cool

  const handlePaste = e => {
    navigator.clipboard.readText()
      .then(text => {
        var url_regex = /^https[:\/A-Za-z0-9\.\_]+(spotify|apple)[:\/A-Za-z0-9\.\_?=]+/;
        if (url_regex.test(text)) {
          document.getElementById('urlinput').value = text;
          setUrl(text);
        }
      })    
  }

  function showSpinner() {
    document.getElementById('contents').style.display = 'none';
    document.getElementById('loader-container').style.display = 'block';
  }

  function hideSpinner() {
    document.getElementById('loader-container').style.display = 'none';
    document.getElementById('contents').style.display = 'block';
  }

  function requestSong(song_id, url = undefined) {
    showSpinner();
    let api_url =  process.env.REACT_APP_API_URL
    let req = {
      method: 'POST',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(
          {
              "id": song_id
          }
      )
    }

    if (url) {
      api_url = api_url.concat("search");
      req.body = JSON.stringify(
          {
              "url": url
          }
      );
    }

    fetch(api_url, req)
      .then((response) => {
        if (!response.ok) {
          throw Error;
        }
        return response.json();
      })
      .then((data) => {
        if ('error' in data){
          renderError();
        }
        else {
          setSongId(data.song_id);
          setName(data.song_name);
          setSpotUrl(data.spot_song_url);
          setAppleUrl(data.apple_song_url);
          setArtists(data.song_artists);
          setImgUrl(data.spot_image_url);
          renderSong(data.song_name, data.spot_song_url, data.apple_song_url, data.spot_image_url, data.song_artists);
          navigate({
            search: `?id=${data.song_id}`
          });
        }
        hideSpinner();
      })
      .catch((error) => {
        hideSpinner();
      })
    
  };

  function renderError() {
    document.getElementById('possibleerror').innerHTML = '<p>Sorry, there was a problem with that song URL.</p>';
  }

  function renderSong(s_name, s_spot_url, s_apple_url, s_img_url, s_artists) {
    if (s_name != '') {
      let finalUrl = window.location.href;
      let finalName = encodeURIComponent(s_name).replaceAll("'", "%27")
      let finalArtists = encodeURIComponent(s_artists).replaceAll("'", "%27")
      document.getElementById('contents').innerHTML = `<div id='response'><div id='backlinkbox'><a id='backlink' href='/'>\< back to search page</a></div><br/>
        <img id='albumcover' src='${s_img_url}' /> <br/>
        <div id='songartist'>
          <h1>${s_name}</h1>
          <h3>${s_artists}</h3>
        </div> <br/>
        <div id='links'>
          <a href='${s_spot_url}' target='_blank' class='reslink'><img class='resicon' alt='spotify' src='spotifyblack.png'></a>
          <a href='${s_apple_url}' target='_blank' class='reslink'><img class='resicon' alt='apple music' src='applemusicblack.png'></a>
        </div> <br/>
        <div id='sharediv'>
          <button id='sharebutton' onClick="navigator.clipboard.writeText(window.location.href);document.getElementById('sharebutton').innerHTML='copied!';">copy link</button>
          <button id='textbutton' onClick="window.location = 'sms://?&body=${finalUrl}%0ACheck out %22${finalName}%22 by ${finalArtists}'">text link</button> <br/>
        </div><br/>
        <div id='sharediv2'>
          <a class="twitter-share-button" href="https://twitter.com/intent/tweet?text=Yo listen to %22${finalName}%22 by ${finalArtists} rn%0A&url=${finalUrl}"data-size="large">
            <div class="twitterbutton">tweet</div>
          </a>
        </div>
      </div>`
    }
  }

  const handleChange = e => {
    setUrl(e.target.value);
  };

  const handleSubmit = e => {
    try {
      requestSong(1, url);
      renderSong(name, spotUrl, appleUrl, imgUrl, artists);
    }
    catch(err) {
      console.log(err);
    }

    e.preventDefault();
  };

  return (
    <div className="App">

        <div id="loader-container">
          <div id="load-spinner"></div>
        </div>

        <div id="contents">
          <div id='initialcontents'>
            <form id="urlform" onSubmit={handleSubmit} >
              <div id="titleinfo">
                <h1>song2song</h1>
                <p>
                  making sharing music with big groups easy.
                </p>
                <p>
                  paste a song url copied from <b>spotify</b> or <b>apple music</b> below and get a shareable link that gives access to that song
                  on both streaming services.
                </p>
                <p>
                  perfect for letting the people know what rips.
                </p>
              </div>
              <button id='pasteclipbutton' onClick={handlePaste} ><b>paste song url</b></button> 
              <br/>
              <input type="text" id="urlinput" placeholder="song url from spotify or apple music" value={url} onChange={handleChange} />
              <br/>
              <input type="submit" id='searchbutton' value="search"></input>
            </form>
          </div>
        </div>
        <div id='possibleerror'></div>
        <div className="lamp">
            <div className="lava">
                <div className="blob"></div>
                <div className="blob"></div>
                <div className="blob"></div>
                <div className="blob"></div>
                <div className="blob"></div>
                <div className="blob"></div>
                <div className="blob"></div>
                <div className="blob"></div>
                <div className="blob top"></div>
                <div className="blob bottom"></div>
            </div>
        </div>
        <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
            <defs>
                <filter id="goo">
                <feGaussianBlur
                    in="SourceGraphic"
                    stdDeviation="10"
                    result="blur"
                />
                <feColorMatrix
                    in="blur"
                    mode="matrix"
                    values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7"
                    result="goo"
                />
                <feBlend in="SourceGraphic" in2="goo" />
                </filter>
            </defs>
        </svg>
    </div>
  );
}

export default App;
